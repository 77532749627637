export const products = [
    {
      feature: "Fruits",
      description:
        "They come in a wide variety of shapes, sizes, colors, and flavors, and can be consumed either fresh or processed.",
      image:
        "https://images.pexels.com/photos/209339/pexels-photo-209339.jpeg"
    },
    {
      feature: "Meats",
      description:
        "There are different types of meat, including beef, pork, chicken, lamb, and fish, each with its own unique taste and nutritional profile.",
      image:
        "https://images.pexels.com/photos/618775/pexels-photo-618775.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"},
    {
      feature: "Milk and Cheese",
      description:
        "There are many different types of dairy products, including milk, cheese, yogurt, and butter, each with its own unique taste and nutritional profile.",
      image:
        "https://i.shgcdn.com/5e76531a-9a38-448f-8c3c-6ec205c54e93/-/format/auto/-/preview/3000x3000/-/quality/lighter/",
    },
    {
      feature: "Condiments",
      description:
        "Condiments are often used in small quantities to enhance the flavor of a dish, and can be an important part of many cuisines around the world.",
      image:
        "https://www.saborusa.com/wp-content/uploads/2019/10/Ponle-sabor-a-tu-vida-con-un-buen-condimento-Foto-destacada.png",
    },
  ];