export const questions = [
 
 
  {
    question: "How can I know if a product is available for purchase online?",
    answer:
      "You can check the availability of a product on our online store by searching for the specific product on our website. \n" +
      "\n" +
      "If the product is available, it will be displayed as available for purchase. If it's not available, it will be indicated as sold out or out of stock.\n" +
      "\n" +
      "You can also contact us through our customer service to get more information about the product availability.\n" 
    },
  {
    question:
      "What are the available payment options and are they secure?",
    answer:
      "We offer several secure payment options, such as credit and debit card, bank transfer, and online payment through secure payment gateways \n" +
      "\n" +
      "All our payment options are encrypted and comply with industry security standards to ensure the safety of your personal and financial data\n" +
      "\n" +
      "You can select your preferred payment option during the checkout process on our website.\n" 
    },
  {
    question: "What is the shipping cost and how long will it take for my order to arrive?",
    answer:
      "The shipping cost may vary depending on the location and size of the order. You can check the specific shipping information on the checkout page of our website before placing your order.\n" +
      "\n" +
      "There may also be free or discounted shipping options for orders over a certain value. As for the delivery time, it depends on the customer's location and the availability of the product in our warehouse. \n" +
      "\n" +
      " However, we usually deliver orders within 1 to 2 business days. If you want more information, feel free to contact our customer service.\n" 
   },
   {
    question: "What happens if I receive a damaged or incorrect product?",
    answer:
    "If you receive a damaged or incorrect product, we are very sorry for the inconvenience. Please contact our customer service as soon as possible so we can resolve the issue. \n"+
    "\n" +
    "We will ask you to provide us with a detailed description of the problem and, if possible, some photos of the product so we can assess the situation. \n"+
    "\n" +
    "Depending on the case, we may offer a refund or a replacement of the damaged or incorrect product. We want to ensure that you are satisfied with your purchase, so we will do everything we can to resolve any issues you may have. \n"
   }
   

  ];
