import React from 'react'
import Questions from '../../components/Questions/Questions'
export const Help = () => {
  return (
    <> 
      <Questions />
    </>
   

  )
}
