import React from "react";
import ProductsToSale from "../../components/ProductsToSale/ProductsToSale";

export const Products = () => {
    return (
      <div className="App">
    <ProductsToSale />
    </div>
    )
  }
  