export const features = [
  {
    feature: "Choose what you want",
    description: "Select items from your favorite grocery stores in the app.",
    image:
      "https://st3.depositphotos.com/4323461/15156/v/600/depositphotos_151567088-stock-illustration-girl-teenager-online-ordering-the.jpg",
  },
  {
    feature: "See real-time updates",
    description: "Expert shoppers select, you chat, we deliver.",
    image:
      "https://img.caminofinancial.com/wp-content/uploads/2017/09/05203705/fooddelivery.jpeg",
  },
  {
    feature: "Get your items same-day",
    description:
      "Pick a convenient time for you. Enjoy 100% quality guarantee on every order.",
    image:
      "https://news.yale.edu/sites/default/files/styles/featured_media/public/food-desert_v05.jpg?itok=9b2yV0jx&c=ccbdeba1ef24c33a0cd17175ca324858",
  },
];
