import React from 'react'
import Card from '../../components/Card/Card'

export const About = () => {
  return (
    <>
    
    <Card />
    </>
  )
}
