export const growth = [
  
  {
    field: "Fresh products",
    description: "from local grocers to chain stores",
  },
  {
    field: "A lot of cities",
    description: "serving in different locations",
  },
  {
    field: "Many orders",
    description: "delivered or picked up yearly",
  },
];
