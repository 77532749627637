export const programs_shopper = {

  slogan: "Become a Shopper",
  arr: [
    {
      title: "Make money with us",
      body: [
        "Shopper Opportunities",
        "Become a Shopper",
        "In-Store Employee For Instacart",
        "Earnings"
      ],
    },
    {
      title: "Shopper help",
      body: [
        "Help",
        "Contact",
        "Safety"
      ],
    },
  ],
};
